/* on modal prime react drop-down appear in backside of the modal */
.p-dropdown-panel {
  z-index: 999999 !important;
}

.pwd-input-grp .form-control:focus {
  border-color: rgb(222, 226, 230) !important;
}

.blink-soft {
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.tableIconMR10 li {
  margin-right: 10px !important;
}

.tableIconMR10 li img {
  height: 20px !important;
  width: 20px !important;
}

.scrollable-list {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.p-tabview-panels {
  padding-top: 0;
}

:root {
  --clr-white: rgb(255, 255, 255);
  --clr-black: rgb(0, 0, 0);
  --clr-light: rgb(245, 248, 255);
  --clr-light-gray: rgb(196, 195, 196);
  --clr-blue: #2f2e7b;
  --clr-light-blue: #2f2e7b;
}

.upload-area {
  width: 100%;
  background-color: var(--clr-white);
  border: 2px solid #2f2e7b;
  border-radius: 24px;
  padding: 2rem 1.875rem 5rem 1.875rem;
  text-align: center;
}

.upload-area__title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.3125rem;
}

.upload-area__paragraph {
  font-size: 0.9375rem;
  color: var(--clr-light-gray);
  margin-top: 0;
}

.upload-area__tooltip {
  position: relative;
  color: #2f2e7b;
  cursor: pointer;
  transition: color 300ms ease-in-out;
}

.upload-area__tooltip:hover {
  color: var(--clr-blue);
}

.upload-area__tooltip-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -125%);
  min-width: max-content;
  background-color: var(--clr-white);
  color: var(--clr-blue);
  border: 1px solid var(--clr-light-blue);
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  transition: none 300ms ease-in-out;
  transition-property: opacity, visibility;
}

.upload-area__tooltip:hover .upload-area__tooltip-data {
  opacity: 1;
  visibility: visible;
}

.upload-area__drop-zoon {
  position: relative;
  height: 11.25rem;
  /* 180px */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed var(--clr-light-blue);
  border-radius: 15px;
  margin-top: 2.1875rem;
  cursor: pointer;
  transition: border-color 300ms ease-in-out;
}

.upload-area__drop-zoon:hover {
  border-color: var(--clr-blue);
}

.drop-zoon__icon {
  display: flex;
  font-size: 3.75rem;
  color: var(--clr-blue);
  transition: opacity 300ms ease-in-out;
}

.drop-zoon__paragraph {
  font-size: 0.9375rem;
  color: var(--clr-light-gray);
  margin: 0;
  margin-top: 0.625rem;
  transition: opacity 300ms ease-in-out;
}

.drop-zoon:hover .drop-zoon__icon,
.drop-zoon:hover .drop-zoon__paragraph {
  opacity: 0.7;
}

.drop-zoon__file-input {
  display: none;
}

.stateList .primeListBox .p-listbox-list-wrapper ul li.p-highlight {
  color: #000 !important;
  background: transparent !important;
}

.stateList .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #000 !important;
  background: transparent !important;
}

.tableIconMR10 div li div img {
  height: 24px !important;
  width: 24px !important;
}

.grey-scale-sol-grid {
  background-color: #f2f2f2;
}