.btn-cancel {
  color: #000;
  background-color: #e2e2e2;
  border-color: #fbfbfb;
}

.ch-max-width {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 26ch;
}

.cursor-pointer {
  cursor: pointer;
}

.delete-icon-f-size {
  font-size: 1.2rem;
}

.action-icon-template-w {
  width: 100px
}

.add-ddl-selection {
  font-size: 0.812rem;
  line-height: 1.4rem;
  vertical-align: top;
  margin-bottom: 0.2rem;
  color: #808080;
}

.pwd-input-grp input {
  border-right: 0;
}

.pwd-input-grp .input-group-text {
  border-left: 0;
  background-color: transparent;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.p-datatable-emptymessage td {
  text-align: center !important;
  font-weight: 600;
  font-size: larger;
}

.mandatory {
  font-weight: bold;
  color: rgb(255, 0, 0);
  font-size: 1rem;
  top: -0.475em !important;
}

.search-grp .searchIptGrpTxt {
  width: 45px;
  background-color: transparent;
  border-right: none;
  border-color: #dee2e6;
}

.search-grp .searchInput {
  border-left: none;
  padding-left: 0;
}

.search-grp .searchInput:focus {
  border-color: #dee2e6;
}

.name-circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background-color: #808080;
  text-align: center;
  display: block;
  letter-spacing: 0.8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeBorderFromModal .modal-header {
  border-bottom: none;
}

.removeBorderFromModal .modal-footer {
  border-top: none;
}

.removeBorderFromModal .modal .modal-dialog .modal-content .modal-body,
.removeBorderFromModal .modal .modal-dialog .modal-content .modal-footer,
.removeBorderFromModal .modal .modal-dialog .modal-content .modal-header {
  padding: 1.2rem 1.4rem;
}

.waltz-filter img {
  cursor: pointer;
}

.waltz-filter .dropdown-menu {
  width: 13rem;
  padding: 1rem;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, .2);
  border: 0;
}

.waltz-filter li {
  list-style: none;
}

.waltz-filter li .status {
  display: flex;
  align-items: center;
}

.glass-order-solution-dropDown {
  border: 1px solid #dee2e6 !important;
}

.sidebar-favicon-logo {
  line-height: 1;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-size: 24px;
  padding: 0;
  border-radius: 0;
}

.search-button-clear {
  border: 1px solid #ced4da;
  border-radius: 2px;
}

.order-status-height {
  height: 45px;
}

.label-text-role {
  color: #808080;
  font-size: 0.812rem;
}

.role-module-tb-head {
  font-weight: bold !important;
  font-size: 1rem !important;
}

.role-cross-icon-w {
  width: 18px !important;
}

.role-td-f-size {
  font-size: 0.975rem !important;
}

.border-white {
  border: 1px solid white;
}

.table-container {
  position: relative;
  overflow: scroll;
  max-height: 800px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.sticky-header th {
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: #ced4da;
}

.sticky-header th,
.sticky-header td {
  padding: 20px 20px;
  text-align: left;
  border: 1px solid #dee2e6;
}

.resetFilters {
  float: right;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
}

.resetFilters:hover {
  text-decoration: underline;
}

.release-modal-header {
  border-bottom: 0;
  padding: 20px 26px !important;
}

.release-modal-body {
  padding: 0px 26px !important;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
  line-height: 31.5px;
  text-align: left;
}

.release-modal-footer {
  border-top: 0;
}

.release-modal-content {
  border-radius: 15px;
}

.p-listbox .p-listbox-item.p-highlight {
  background-color: transparent !important;
  color: inherit !important;
  outline: none !important;
  box-shadow: none !important;
}

.p-listbox-item {
  margin-right: 2px;
  margin-left: -9px;
}

.font-bold {
  font-weight: bold;
}

.send-icon {
  width: 24px;
  height: auto;
  cursor: pointer;
}

.p-checkbox-region {
  margin-left: -3px !important;
}

.p-listbox .p-listbox-header {
  padding: 0.50rem 1.00rem !important;
}

.p-listbox .p-listbox-list .p-listbox-item {
  padding: 0.50rem 0.75rem 0rem;
}

.bg-coral {
  color: white;
  background-color: coral;
}

.bg-indigo {
  color: white;
  background-color: rgb(125, 12, 255);
}

.active-dot {
  height: 10px;
  width: 10px !important;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.inactive-dot {
  height: 10px;
  width: 10px !important;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.p-multiselect-items .p-multiselect-item span {
  width: 100%;
}

.p-dropdown-items .p-dropdown-item span {
  width: 100%;
}

.dashboardWrapper {
  position: relative;
}

.dashboardWrapper .searchMain {
  width: 100%;
  display: flex;
  align-items: center;
}

/* .dashboardWrapper .searchMain .p-inputtext,
.dashboardWrapper .searchMain .custom-placeholder,
.dashboardWrapper .searchMain .p-multiselect-label{
  font-size: 12px;
} */

.dashboardWrapper .searchMain .p-dropdown {
  margin-right: 24px;
  width: 100%;
}

.dashboardWrapper .searchMain .p-dropdown:last-child {
  margin-right: 0;
}

hr {
  border: 1px solid #ced4da;
}

.p-datepicker {
  z-index: 2005 !important;
}

.salutation .input-group-text {
  padding: 0;
  width: 6.5rem;
  outline: none;
  background: transparent;
  border: none;
  border-radius: 0;
}


/* .input-group-text > .p-dropdown {
}
.input-group-text > .p-dropdown .p-dropdown-trigger{
  padding-right:15px ;
} */

.addContactWrapperMain .p-dropdown .p-dropdown-trigger {
  width: 1.3rem;
}

.input-group-text>.p-dropdown {
  border-radius: 5px 0 0 5px !important;
}

.input-group-text>.p-dropdown .p-focus {
  border: 1px solid #dee2e6 !important;
}

.input-group-text>.p-dropdown .p-inputwrapper-filled {
  border: 1px solid #dee2e6 !important;
}

.input-group-text>.p-dropdown .p-focus:focus {
  border: 1px solid #dee2e6 !important;
}

.input-group-text>.p-dropdown .p-inputwrapper-filled {
  border: 1px solid #dee2e6 !important;
}

.toptanProjects,
.WinProTable {
  position: relative;
  width: 100%;
}

.toptanProjects tr th,
.toptanProjects tr td,
.WinProTable tr th,
.WinProTable tr td {
  padding: 10px 6px;
  font-size: 12px;
  word-wrap: break-word;
  white-space: inherit;
  color: #4D4D4D;
}

.WinProTable tr td {
  padding: 15px 6px;
}

.wonProHldr {
  position: relative;
  display: flex;
  flex-direction: column;
}

.wonProHldr p {
  position: relative;
  font-size: 16px;
}

.wonProHldr p.bold {
  font-weight: 700;
  font-size: 20px;
}

.TotalValueHldr {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 15px;
}

.TotalValueHldr span {
  position: relative;
  display: block;
  width: 100%;
}

.TotalValueHldr span.number {
  font-size: 18px;
  font-weight: 700;
}

.TotalValueHldr span.txt {
  font-size: 12px;
}

.wonGrapHldr {
  position: relative;
}

.wonGrapHldr .number {
  /* position: absolute; */
  /* width: 150px; */
  font-size: 26px;
  font-weight: 700;
}

.dashboard-lable {
  font-size: 14px;
}

.panel-only {
  transform: rotate(90deg);
}

.p-tree {
  height: 50vh;
  overflow: auto;
}

.solution-btn>div {
  width: fit-content;
}


.badge-glass {
  background-color: #FFA800;
  color: #fff;
  font-size: small;
  padding-bottom: 5px;
}

.badge-waltz {
  background-color: #8785FF;
  color: #fff;
  font-size: small;
  padding-bottom: 5px;
}


.circle-win {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #2399DA;
  border-radius: 50%;
  margin-right: 5px;
}

.content-wrapper,
.footer,
.navbar .navbar-menu-wrapper {
  background-color: #f2f2f2 !important;
}

.circle-pipeline {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #F2BE59;
  border-radius: 50%;
  margin-right: 5px;
}

.dashboard-chart {
  height: 50%;
}

.dashboard-chart2 {
  height: 47%;
}

.revanue-chart-font-color {
  color: #939BA2;
}

.win-color {
  color: #2399DA;
  font-size: 8px;
}

.pipeline-color {
  color: #F2BE59;
  font-size: 8px;
}

.hover-list {
  cursor: pointer;
}


.customProgress {
  height: 10px;
  width: 100%;
  border-radius: 10px;


}

/* .customProgress .p-progressbar-value{
  background-color: yellow;
} */

.progress-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.customProgressHldr {
  position: relative;
}

.customProgressHldr .p-progressbar-label {
  display: none;
}

.progressBarFont {
  font-size: 12px;
}

.productChartFont {
  margin-top: 15%;
  font-size: 20px;
}

.productChart-image {
  margin-top: 7%;
  width: 150px;
  height: 150px
}

.waltz-slide .p-progressbar-value {
  background-color: #50D866 !important;
}

.waltz-close .p-progressbar-value {
  background-color: #F2BE59 !important;
}

.waltz-swing .p-progressbar-value {
  background-color: #2399DA !important;
}

.waltz-glide .p-progressbar-value {
  background-color: #FF8989 !important;
}

.wardrobe .p-progressbar-value {
  background-color: blue !important;
}

.wallways .p-progressbar-value {
  background-color: #832cd6 !important;
}

.panel .p-progressbar-value {
  background-color: #FF7F3F !important;
}

.circle-waltz-close {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #F2BE59;
  border-radius: 50%;
  margin-right: 5px;
}

.circle-waltz-slide {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #50D866;
  border-radius: 50%;
  margin-right: 5px;
}

.circle-waltz-swing {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #2399DA;
  border-radius: 50%;
  margin-right: 5px;
}

.circle-waltz-glide {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #FF8989;
  border-radius: 50%;
  margin-right: 5px;
}

.circle-wardrobe {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
}

.circle-wallways {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #832cd6;
  border-radius: 50%;
  margin-right: 5px;
}

.circle-panel {
  display: flex;
  width: 7px;
  height: 7px;
  background-color: #FF7F3F;
  border-radius: 50%;
  margin-right: 5px;
}

.waltz-close-color {
  font-size: 8px;
  color: #F2BE59;

}

.waltz-slide-color {
  font-size: 8px;
  color: #50D866;
  margin-right: 5px;

}

.waltz-swing-color {
  font-size: 8px;
  color: #2399DA;

}

.waltz-glide-color {
  font-size: 8px;
  color: #FF8989;
  margin-right: 5px;
}

.wardrobe-color {
  color: blue;
  font-size: 8px;
}

.wallways-color {
  color: #832cd6;
  font-size: 8px;
}

.panel-color {
  color: #FF7F3F;
  font-size: 8px;
}

.hide-toolbar .p-editor-toolbar {
  display: none;
}

.cursor-pointer-no-drop {
  cursor: no-drop;
}

/* .p-datatable .p-datatable-tbody>tr>td {
  font-size: 14px !important;
} */

.card {
  box-shadow: #919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px !important;
}

.cursor-no-drop {
  cursor: no-drop;
}

.directory-master-search-w {
  width: 47% !important;
}

.directory-master-hover-effect {
  cursor: pointer;
  transition: color 0.3s ease;
}

.directory-master-hover-effect:hover {
  color: blue;
}

.p-inputswitch {
  width: 2.6rem !important;
  height: 1.25rem !important;
}

.p-inputswitch-slider::before {
  width: 0.8rem !important;
  height: 0.8rem !important;
  margin-top: -0.42rem !important;
}

.p-rowgroup-header-name {
  width: 100% !important;
}

.switch-w {
  width: 72vw;
}


.p-disabled:disabled {
  opacity: 70% !important;
  color: #000 !important;
}

.list-group {
  position: relative;
}

.list-group h2 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.list-group .list-group-item {
  border: none;
  border-radius: 0.5rem;
  background: #fff;
  cursor: pointer;
  position: relative;
}

.icon-disabled {
  opacity: 30%;
  cursor: no-drop;
}

.sliderWrapper{
  position: relative;
  display: block;
  background: #FF5252;
  padding: 15px 15px 15px 100px;
  color: #fff;
}

.sliderWrapper .counter{
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 36px;
  font-size: 12px;
}

.sliderWrapper .close{
  font-size: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 15px;
  cursor: pointer;
  z-index: 1;
}

.sliderWrapper .slick-slider{
  display: flex;
  align-items: center;
}

.sliderWrapper .slick-slider .slideTxt{
  font-size: 14px;
}

.sliderWrapper .slick-prev, 
.sliderWrapper .slick-next{
  width: 26px;
  height: 26px;
}
.accerssories-icon {
  height: 20px !important;
  width: 20px !important;
}

.accerssories-image-dropdown {
  width: 25% !important;
  height: 39px !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.table-responsive .table td img {
  border-radius: 0%
}

.ql-editor.ql-blank::before {
  color: #D3D3D3 !important;
  font-style: normal !important;
}

.content-email-for-editor .ql-toolbar {
  display: none !important;
}

.content-email-for-editor .ql-container {
  border: none !important;
  padding: 0;
}


#importArchitectUser>.modal-dialog {
  max-width: 1020px !important;
}

.p-datepicker {
  z-index: 9999 !important;
}

.p-dropdown-color .p-dropdown-label:not(.p-placeholder) {
  color: black !important;
  /* Selected text is black */
}

.p-dropdown-color-date-picker .p-inputtext:not(.p-placeholder) {
  color: black !important;
  /* Selected date text is black */
}

.top-bar {
  background-color: #e0e0e0;
  padding: 12px;
  font-size: 14px;
  color: #333;
  border-radius: 0.2rem !important;
}

.audio-player {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  border-radius: 8px;
  gap: 10px;
}

.audio-player span {
  display: flex;
  align-items: center;
}

.sliderWrapper .slick-prev{
  left: -90px;
}

.sliderWrapper .slick-next{
  right:auto;
  left: -40px;
}
.sliderWrapper .slick-next::before, 
.sliderWrapper .slick-prev::before{
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
}
.sliderWrapper .slick-next::before{
  content: "\f106";
}
.sliderWrapper .slick-prev::before{
  content: "\f107";
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .wonProHldr p{
    margin-bottom: 15px;
  }
  .wonGrapHldr .number{
    font-size: 16px;
  }
}

@media (max-width: 575.98px) {
  .wonProHldr p{
    margin-bottom: 15px;
  }
  .wonGrapHldr .number{
    font-size: 16px;
  }

}
.audio-player input {
  flex: 1;
  margin: 0 10px;
}

.meeting-images {
  height: 255px;
  width: 50%;
}

.exhibitionsImg {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.customPre {
  white-space: pre-wrap;
  background-color: #f3f4f6;
  border-radius: 5px;
}

.instagram-content a.custom-link {
  color: rgba(31, 59, 179, 0.7) !important;
}

.custom-toast-body {
  white-space: normal !important; /* Allow multi-line text */
  word-wrap: break-word !important; /* Break long words */
  text-align: center !important; /* Center align text */
  font-size: 16px !important; /* Adjust text size */
  width: 400px !important;
}

.toast-libary{
  word-wrap: break-word !important;
  word-break: break-word !important;
}