.albums-add-album {
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thumbnail-box {
    width: 100%;
    height: 194px;
    border-radius: 10px;
    border: 1px dotted #909090;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
}

.selectFileBtn {
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid black;
    color: black;
}

.selectFileBtn {
    border-color: #525252;
    background-color: #F2F2F2;
    color: #000000;
    size: 14px;
}

/* ////// */
/* .p-fileupload-basic .custom-select-file-btn {
border: 1px solid #525252;
background-color: #F2F2F2;
color: #000000; 
font-size: 14px;
font-weight: normal; 
border-radius: 4px; 
padding: 8px 16px; 
cursor: pointer; 
} */

/* .p-fileupload-basic .custom-select-file-btn:hover {
background-color: #e6e6e6; 
border-color: #404040; 
} */
/* ////// */
.p-fileupload-choose span.pi {
    display: none !important;
}

.p-fileupload-basic .p-fileupload-choose:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #525252 !important;
}

.p-fileupload-basic .p-fileupload-choose {
    border: 1px solid #525252 !important;
    background-color: #F2F2F2 !important;
    color: #525252 !important;
}

.cancelBtn {
    border-radius: 4px;
    color: #212529;
    border: 1px solid #E2E2E2 !important;
    background-color: #E2E2E2;
}

.addBtn {
    border-radius: 4px;
    color: white;
    border: 1px solid #525252 !important;
    background-color: #525252;
}

.custom-select-file-btn .p-button .p-component .p-fileupload-choose {
    display: none;
}

.custom-select-file-btn svg.p-icon {
    display: none !important;
}

.modalBtn .custom-button-label .p-button-label {
    font-size: 14px;
}

.p-dialog-content {
    border-radius: 4px;
}

.p-tree-container {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    list-style: none;
}

.p-tree-container .p-treenode {
    position: relative;
    padding: 0;
    margin: 0;
}

.p-tree-container .p-treenode .p-treenode-content {
    position: relative;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    display: flex;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    padding: 15px;
}

.p-tree-container .p-treenode .p-treenode-content .rightIconMain {
    position: absolute;
    display: flex;
    align-items: center;
    top: 15px;
    right: 0;
}

.p-tree-container .p-treenode .p-treenode-content .rightIconMain .folderCount {
    display: flex;
    align-items: center;
    font-size: 12px;
    background: #34B1AA;
    color: #fff;
    border-radius: 15px;
    margin-right: 15px;
    padding: 4px 12px;
}

.p-tree-container .p-treenode .p-treenode-content .rightIconMain .imageCount {
    display: flex;
    align-items: center;
    font-size: 12px;
    background: #0DCAF0;
    color: #fff;
    border-radius: 15px;
    margin-right: 15px;
    padding: 4px 12px;
}

.album-location {
    background-color: #f2f2f2 !important;
    padding-left: 56px;
    padding-top: 15px;
}

.album-location .font-bold {
    font-weight: 800;
}

.photos-grid {
    background: var(--surface-card);
    border: var(--card-border);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    justify-content: center !important;
}

.photos-grid .grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -.5rem;
    margin-left: -.5rem;
    margin-top: -.5rem;
}

.select-text {
    padding-left: 1.5rem;
    padding-top: 1rem;
}

.image-container {
    position: relative;
    width: 275px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.normal-image {
    width: 100%;
    height: 100%;
    display: block;
}

.hover-image {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    text-align: center;
    padding: 10px 0;
    z-index: 1;
    display: none;
    padding: 10px;
}

.hover-image .display-name {
    font-size: 10px;
    font-weight: 800;
    display: block;
    width: 60%;
    text-align: left;

}


.image-container:hover .hover-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.SearchresultWrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, 25%);
    margin: 0 -.9375em
}

.SearchresultWrapper li {
    position: relative;
    margin: 0 0 1.875em 0;
    padding: 0 .9375em;
    display: inline-block
}

.SearchresultWrapper li .imgHldr {
    position: relative;
    width: 100%;
    aspect-ratio: .99/1;
    overflow: hidden;
    border: 0.5px solid
}

/* .SearchresultWrapper li .imgHldr.active,
.SearchresultWrapper li .imgHldr:hover {
border: 2px solid #06b6d4
} */

.SearchresultWrapper li .imgHldr.active .checkBoxMain,
.SearchresultWrapper li .imgHldr.active .imgTitle,
.SearchresultWrapper li .imgHldr:hover .checkBoxMain,
.SearchresultWrapper li .imgHldr:hover .imgTitle {
    display: block
}

.SearchresultWrapper li .imgHldr .checkBoxMain {
    display: none;
    position: absolute;
    top: .625em;
    right: .625em;
    width: auto;
    height: auto
}

.SearchresultWrapper li .imgHldr .checkBoxMain .form-check-input {
    margin-top: 0;
    border: 1px solid #fff
}

.SearchresultWrapper li .imgHldr img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.SearchresultWrapper li .imgHldr .imgTitle {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: .9375em;
    background: rgba(0, 0, 0, 1)
}

.SearchresultWrapper li .imgHldr .imgTitle .downShareMain {
    position: relative;
    display: flex;
    align-items: center;
}

.SearchresultWrapper li .imgHldr .imgTitle .downShareMain .albums {
    font-size: .875em;
    color: #fff
}

.SearchresultWrapper li .imgHldr .imgTitle .downShareMain .iconsHldr {
    /* width: 70%; */
    display: flex;
    justify-content: end;
}

.SearchresultWrapper li .imgHldr .imgTitle .downShareMain .iconsHldr a {
    color: #fff;
    margin-right: .9375em;
    display: inline-block
}

.SearchresultWrapper li .imgHldr .imgTitle .downShareMain .iconsHldr a:last-child {
    margin-right: 0
}

@media (min-width:576px) and (max-width:767.98px) {
    .searchHldr {
        display: block
    }

    .searchHldr .rightHldr {
        margin-top: .9375em
    }

    .librarieImgHldr {
        grid-template-columns: repeat(auto-fit, 50%)
    }

    .SearchresultWrapper {
        grid-template-columns: repeat(auto-fit, 50%)
    }
}

@media (max-width:575.98px) {
    .searchHldr {
        display: block
    }

    .searchHldr .rightHldr {
        margin-top: .9375em
    }

    .librarieImgHldr {
        grid-template-columns: repeat(auto-fit, 50%)
    }

    .SearchresultWrapper {
        grid-template-columns: repeat(auto-fit, 50%)
    }
}

.album-tree .p-tree-container .p-tree-empty-message .p-treenode-content {
    margin-bottom: 0 !important;
}

.btnUpdatetag:hover,
.btnUpdatetag {
    background-color: #697CCB;
    color: #fff;
    border-color: #697CCB;
}

.btnDownload:hover,
.btnDownload {
    background-color: #34B1AA;
    border-color: #34B1AA;
    color: #fff;
}

.btnShare:hover,
.btnShare {
    background-color: #525252;
    border-color: #525252;
    color: #fff;
}

.libraryFontColor {
    color: #808080 !important
}

#libraryLocation {
    color: #808080 !important
}

.tagsNotRecordsFound {
    font-weight: 600;
    font-size: 17px;
    color: #4b5568
}